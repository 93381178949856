.service-package {
  .MuiFormControl-root {
    width: 100%;
  }

  .button {
    &.new-service-package {
      float: right;
      color: white;
      background: black;
      box-shadow: 0 0 5px black;
      font-weight: 600;
      padding: 10px 30px 10px 20px;
      font-size: 14px;
      margin-top: 10px;

      svg {
        color: $color-orange;
        margin-right: 25px;
        font-size: 18px;
      }
    }
  }

  .list-card {
      background-color: $color-white;
  }

  .MuiCard-root{
    display: flex;
    flex-direction: column;
  }
  .MuiCardActions-root{
    margin-top: auto;
  }
}
