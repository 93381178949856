.filter__holder {
  background: white;
  padding: 15px;
  padding-bottom: 25px;
  margin-top: 15px;
  margin-bottom: 25px;
  box-shadow: 0 0 10px #A8A8A8;

  .MuiFormControl-root {
    width: 100%;

    .MuiFilledInput-root {
      border-radius: 4px;

      .MuiFilledInput-underline {
        &:before {
          border-bottom: 0;
        }
      }
    }
  }

  .MuiInput-underline:before {
    display: none;
  }

  .reset-button {
    span {
      text-transform: none;
      color: #4a4a4a;
    }
  }
}
