.dataTable__holder {

  .MuiTable-root {
    min-width: 1400px;
  }

  .MuiTableCell-root {
    padding: 3px;
  }
}
