body {
	background: $color-gray-bg;
}


@keyframes "App-logo-spin" {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.content {
	text-align: left;

	.App {
	  padding: 32px;

		.btn {
			background: $color-orange;
			color: white;
			padding: 7px 30px;

			&.btn-login {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
.page {
	padding: 10px;

	.MuiGrid-container {
		padding: 10px;
	}

	.MuiFormControl-root {
		min-width: 200px;
		max-width: 300px;
	}

	&.MuiContainer-root {
		min-width: 1680px;
	}
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
.App-link {
	color: #61dafb;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

h1 {
	font-weight: 400;
	font-size: 2.5rem;
	margin-bottom: 10px;
}

.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}


.d-block {
	display: block;
}

.icon-button {
	border: 2px solid #ededed !important;
	border-radius: 4px;
	height: 44px;
	text-transform: uppercase;
	text-align: center;
	margin-left: 5px;
	padding-right: 25px;

	svg {
		color: $color-orange;
		margin-right: 25px;
		font-size: 22px;
		border-right: 2px solid #ededed;
		padding-right: 8px;
		margin-top: -10px;
		height: 44px;
		margin-bottom: -10px;
	}

	&.only-icon {
		padding-right: 0;

		svg {
			margin-right: 0;
			border-right: 0;
		}
	}

	&[disabled] {
		font-weight: bold;

		svg {
			color: #a2a2a2;
		}
	}

	&.editDefinition {
		position: absolute;
		right: 15px;
		top: 15px;
		font-weight: bold;
	}
}

.h-100 {
	height: 100%;
}

.active-background {
	background-color: $color-green;
}

.inactive-background {
	background-color: $color-gray-bg;
}
