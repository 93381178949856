.module-loading {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: $background-color-system-loading;

  .MuiCircularProgress-circle {
    color: $color-orange;
  }
}
