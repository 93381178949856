.logo {
  height: 30px;
}

.menu {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  width: 100%;
}

.divider {
  height: 100%;
  width: 2px;
  background-color: #555;
  display: inline;
  margin: 0 15px;
}

.top-bar {
  justify-content: space-between;
  box-shadow: -3px 5px 20px 3px #888888;
}

.naviBar {
  &.MuiAppBar-root {
    background-color: black;
    border: 0;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    justify-content: space-between;
    box-shadow: -3px 5px 20px 3px #888888;
    color: white;
    height: 68px;
    padding: 0 30px;

    button {
      color: #bfbfbf;
      font-weight: bold;
      font-size: 14px;
      text-transform: initial;
      letter-spacing: 0;
      margin-left: 10px;

      &.ml-3 {
        margin-left: 50px;
      }

      .MuiSvgIcon-root {
        margin-right: 10px;
        color: $color-orange;

        &.chevronDown {
          font-size: 18px;
          margin-left: 15px;
          margin-right: 0;
          color: #bfbfbf;
        }
      }
    }

    .grow {
      flex-grow: 1;
    }
  }
}
