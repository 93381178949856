.vehicle-card {
  max-width: 1400px!important;

  .MuiFormControl-root {
    width: 100%;
  }

  .addColorButton {
    color: $color-orange;
    padding-left: 0;
    text-transform: none;

    svg {
      margin-right: 10px;
    }
  }

  .button {
    &.new-card {
      float: right;
      color: white;
      background: black;
      box-shadow: 0 0 5px black;
      font-weight: 600;
      padding: 10px 30px 10px 20px;
      font-size: 14px;
      margin-top: 10px;

      svg {
        color: $color-orange;
        margin-right: 25px;
        font-size: 18px;
      }
    }
  }

  .carOverview {
    padding: 15px;
    text-align: left;

    .car-heading {
      max-width: 100%;
      height: auto;
    }

    h2 {
      font-weight: 400;
      margin-bottom: 0;
    }

    .brand {
      color: #a2a2a2;
    }
  }

  .MuiFormControl-root {
    &.filled {
      background: #e8e8e8;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .MuiInputLabel-formControl {
        top: 7px;
        left: 8px;
        min-width: 200px;
        text-align: left;
      }

      .MuiInputBase-input {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &.outlined {
      border: 1px solid #e8e8e8;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .MuiInputLabel-formControl {
        min-width: 200px;
        text-align: left;
      }

      .MuiInputBase-input {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiAccordion-root {
    .MuiAccordionSummary-content{
      h2 {
        font-weight: 400;
        margin-top: 0;
        padding-left: 30px;
        margin-top: 10px;
        transition: all 0.2s;

        &:not(.Mui-expanded) {
          margin-top: 0px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }

    .MuiAccordionSummary-expandIcon {
      position: absolute;
      left: 0;
      top: 20px;
      color: $color-orange;
      transition: all 0.2s;

      &:not(.Mui-expanded) {
        top: 10px;
      }
    }

    .MuiAccordionDetails-root {
      flex-direction: column;
    }
  }

  .red-dot {
    fill: $color-red;
  }

  .green-dot {
    fill: $color-green;
  }

  .orange-dot {
    fill: $color-orange;
  }

  .grey-dot {
    fill: $color-gray;
  }

  .color-1 {
    fill: blue;
  }

  .card-overview {
    .actions-cell {
      min-width: 145px;
    }
  }

  .card-section {
    .section-header-info {
      min-width: 250px;
    }
  }
}
