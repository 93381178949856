.vehicle-card-create {

  .MuiDialog-paperWidthSm {
    min-width: 650px;
  }

  .autocomplete-option-code {
    width: 600px;
    padding-bottom: 10px;
  }

  .button {
    &.create {
      color: white;
      background: black;
      box-shadow: 0 0 5px black;
      padding: 7px 7px 7px 7px;

      svg {
        color: $color-orange;
        margin-right: 1px;
        font-size: 18px;
      }
    }
  }
}
